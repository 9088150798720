<template>
  <div id="search">
    <div class="search-bgc"></div>
    <div class="container_main">
      <div class="pt-7rem">
        <div class="col-12 text-center" v-if="$store.state.headLogo">
          <!-- <img src="../../assets/img/sign/logo.png" alt=""> -->
          <!-- <img class="logo_hua" src="../../assets/img/sign/logo_h.svg" alt /> -->
        </div>
        <div>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  created() {
    this.$store.state.showNav = true;
  },
  components: {},
};
</script>

<style>
/* .search-list {
  left: 0;
  top: 49px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  z-index: 1;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.31);
  border-radius: 16px;
  margin-top:10px;
  margin-left: -5px;
} */
.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  position: absolute;
  /* display: block; */
  /* width: 0; */
  /* height: 0; */
  /* border-color: transparent; */
  border-style: none;
}
.container_main {
  width: 1350px !important;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* .search-bgc {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -999;
  background: url("../../assets/img/search/bg_search.png") no-repeat center
    center;
} */
.main-content {
  min-width: 1200px !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.com_logo {
  display: none;
}
.logo_hua {
  height: 100px;
}

img {
  vertical-align: middle;
}
.search-items:hover {
  background-color: #eef5ff;
}
@media (max-width: 460px) {
  .logo_hua {
    height: 55px;
  }
}
</style>
